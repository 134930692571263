import React, { useEffect, useState } from 'react';
import { Button, Spin, Table, Tag } from 'antd';
import useRouteQuery from '../../../../../../Utils/useRouterQuery';
import { useExpires } from '../../../../../../Hooks/TT_ELD/Companies';
import SearchInput from '../../../../../../Utils/SearchInput';
import { Link, useLocation } from 'react-router-dom';
import moment from 'moment';

type Data = {
  data?: {
    data: Array<any>;
    count: number;
  };
  isLoading?: boolean;
  refetch?: any;
  isFetching?: any;
};

type numStr = string | number;
interface reportSource {
  id: numStr;
  name: { name: string; id: string };
  owner: { ownerId: number | string | undefined | null; owners: Array<any> };
  companyId: numStr;
  stripe_type: string;
  is_active: boolean;
  subscriptionQuantity: number | string | undefined | null;
  current_period_end: { date: string };
  key: React.Key;
}

const Companies = () => {
  const location = useLocation();
  const [companName, setCompanyName] = useRouteQuery('', 'companyName', (v) => String(v));
  const [usdot, setUsdot] = useRouteQuery('', 'usdot', (v) => String(v));
  const [uid, setUid] = useRouteQuery('', 'uid', (v) => String(v));
  const [sortedInfo, setSortedInfo] = useState<any>({});
  const [status, setStatus] = useRouteQuery('', 'status', (v) => String(v));
  const handleChange = (event: string, inputName: string) => {
    const updateFunctions: Record<string, (value: string) => void> = {
      companyId: setCompanyName,
      usdot: setUsdot,
      uid: setUid,
    };

    if (updateFunctions[inputName]) {
      updateFunctions[inputName](event.trim());
      setSkip(1);
    }
  };
  const [skip, setSkip] = useState<number>(1);
  const { data, refetch, isLoading, isFetching }: Data = useExpires(
    companName,
    usdot,
    status,
    uid,
    skip,
  );
  const onChange = (query: any) => {
    setSkip(1 * parseInt(query.current));
  };
  useEffect(() => {
    if (sortedInfo?.order) {
      setStatus(sortedInfo?.order);
      setSkip(1);
    }
  }, [sortedInfo, location.pathname]);

  const columns: object[] = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Company',
      dataIndex: 'name',
      key: 'name',
      render: ({ name, id }: { name: string; id: string }) => <Link to={`${id}`}>{name}</Link>,
    },
    {
      title: 'Owner',
      dataIndex: 'owner',
      key: 'owner',
      render: ({ owners }: { owners: Array<any> }) => {
        return (
          <div>
            {owners?.map((u) => (
              <div>{u?.user.first_name + ' ' + u?.user.second_name}</div>
            ))}
          </div>
        );
      },
    },
    {
      title: 'Is Active',
      dataIndex: 'is_active',
      key: 'is_active',
      render: (tag: boolean) => (
        <Tag color={tag ? 'geekblue' : 'red'}>{tag ? 'True' : 'False'}</Tag>
      ),
      sorter: (a: any, b: any) => a.is_active?.length - b?.is_active?.length,
      sortOrder: sortedInfo.columnKey === 'is_active' && sortedInfo.order,
      sortDirections: ['false', 'true', ' '],
    },
    {
      title: 'Stripe Type',
      dataIndex: 'stripe_type',
      key: 'stripe_type',
    },
    {
      title: 'Subscription quantity',
      dataIndex: 'subscriptionQuantity',
      key: 'subscriptionQuantity',
      width: 220,
    },
    {
      title: 'Expiration Date',
      dataIndex: 'current_period_end',
      key: 'current_period_end',
      width: 220,
      render: ({ date }: { date: any }) => {
        const formattedDate = date ? moment.unix(date).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]') : '';
        const now = new Date();
        const endDateTime = new Date(formattedDate);
        const timeDiffMilliseconds = endDateTime.getTime() - now.getTime();
        const timeDiffDays = Math.floor(timeDiffMilliseconds / (1000 * 60 * 60 * 24));
        const timeDiffHours = Math.floor((timeDiffMilliseconds / (1000 * 60 * 60)) % 24);
        const day = timeDiffDays ? `${timeDiffDays} days.` : '';
        const hour = timeDiffHours ? `${timeDiffHours} hour.` : '';
        return (
          <div>
            {date && (
              <div style={{ color: timeDiffDays < 0 || timeDiffHours < 0 ? '#fd3e3e' : '' }}>
                {day} {hour}
              </div>
            )}
          </div>
        );
      },
    },
  ];

  const handleChangeIsActive = (query: any, filters: any, sorter: any) => {
    onChange(query);
    const { order, field } = sorter;
    setSortedInfo({ columnKey: field, order });
  };
  return (
    <div className="mainPage">
      <SearchInput
        value={companName}
        placeholder="Search by company"
        onDebounceChange={(event: string) => handleChange(event, 'companyId')}
      />
      <SearchInput
        value={usdot}
        placeholder="Search by USDOT"
        onDebounceChange={(event: string) => handleChange(event, 'usdot')}
      />
      <SearchInput
        value={uid}
        placeholder="Search by UID"
        onDebounceChange={(event: string) => handleChange(event, 'uid')}
      />
      <Button style={{ float: 'right', marginLeft: '15px' }} size={'large'} onClick={refetch}>
        Refresh
      </Button>

      <Spin size="large" spinning={isLoading || isFetching}>
        <Table
          style={{ margin: '15px 0 0 0' }}
          onChange={handleChangeIsActive}
          dataSource={data?.data?.map((u: any, i: number): reportSource => {
            const obj: reportSource = {
              id: u.id,
              name: { name: u?.name, id: u.id },
              owner: { ownerId: u?.ownerId, owners: u?.owners },
              companyId: u?.companyId,
              is_active: u.is_active,
              stripe_type: u?.stripe_type,
              subscriptionQuantity: u.subscription_quantity,
              current_period_end: { date: u?.period_end },
              key: i,
            };
            return obj;
          })}
          columns={columns}
          pagination={{ total: data?.count, showSizeChanger: false }}
        />
      </Spin>
    </div>
  );
};

export default Companies;
