import React, { useEffect, useState } from 'react';
import { useUsersData } from '../../../../../Hooks/TT_ELD/Users';
import SearchOptions from '../../../../../Utils/SearchOptions';
import { SearchResultForCompany } from '../../../../../Utils/SearchResults';
import UsersTable from './UsersTable';
import { Button, Grid, Drawer } from 'antd';
import SearchInput from '../../../../../Utils/SearchInput';
import { useLocation } from 'react-router-dom';
import useRouteQuery from '../../../../../Utils/useRouterQuery';
import { FilterOutlined } from '@ant-design/icons';

const { useBreakpoint } = Grid;

type Data = {
  data?: {
    data: Array<any>;
    count: number;
  };
  isLoading?: boolean;
  refetch?: any;
  isFetching?: any;
};

const Users = () => {
  const screens = useBreakpoint();
  const xs = screens.xs;
  const sm = screens.sm;
  const md = screens.md;
  const lg = screens.lg;
  const xl = screens.xl;
  const xxl = screens.xxl;

  const location = useLocation();
  const [skip, setSkip] = useState<string | number>(1);
  const [sortedInfo, setSortedInfo] = useState<any>({});
  const [companyId, setCompanyId] = useRouteQuery('', 'companyId', (v) => String(v));
  const [userName, setUserName] = useRouteQuery('', 'userName', (v) => String(v));
  const [driverName, setDriverName] = useRouteQuery('', 'driverName', (v) => String(v));
  const [driverEmail, setDriverEmail] = useRouteQuery('', 'driverEmail', (v) => String(v));
  const [licenseNumber, setLicenseNumber] = useRouteQuery('', 'licenseNumber', (v) => String(v));
  const [role, setRole] = useRouteQuery('driver', 'role', (v) => String(v));
  const [status, setStatus] = useRouteQuery('', 'status', (v) => String(v));
  const [uid, setUid] = useRouteQuery('', 'uid', (v) => String(v));
  const [companyUID, setCompanyUID] = useRouteQuery('', 'companyUID', (v) => String(v));
  const [open, setOpen] = useState(false);

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1960);
    };
    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const { data, refetch, isLoading, isFetching }: Data = useUsersData(
    companyId,
    role,
    status,
    driverName,
    driverEmail,
    userName,
    licenseNumber,
    uid,
    companyUID,
    skip,
  );
  const handleChange = (event: string, inputName: string) => {
    const updateFunctions: Record<string, (value: string) => void> = {
      name: setDriverName,
      email: setDriverEmail,
      username: setUserName,
      license: setLicenseNumber,
      uid: setUid,
      companyUID: setCompanyUID,
    };

    if (updateFunctions[inputName]) {
      updateFunctions[inputName](event.trim());
    }
  };

  const onChange = (query: any) => {
    setSkip(query.current);
  };
  useEffect(() => {
    if (sortedInfo?.order) {
      setStatus(sortedInfo?.order);
    }
  }, [sortedInfo, location.pathname]);

  return (
    <div className="mainPage">
      <span style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        {md && (
          <SearchInput
            value={driverName}
            placeholder="Search by name"
            onDebounceChange={(event: string) => handleChange(event, 'name')}
            enterButtonSize="8"
            searchWidth={'240px'}
          />
        )}

        {md && (
          <SearchInput
            value={driverEmail}
            placeholder="Search by email"
            onDebounceChange={(event: string) => handleChange(event, 'email')}
            enterButtonSize="8"
            searchWidth={'240px'}
          />
        )}
        <SearchInput
          value={userName}
          placeholder={isMobile ? 'Search userName' : ' Search by userName'}
          onDebounceChange={(event: string) => handleChange(event, 'username')}
          enterButtonSize="8"
          searchWidth={'240px'}
        />
        {xxl && (
          <SearchInput
            value={licenseNumber}
            placeholder="Search by license"
            onDebounceChange={(event: string) => handleChange(event, 'license')}
            enterButtonSize="8"
            searchWidth={'240px'}
          />
        )}
        {xxl && (
          <SearchInput
            value={uid}
            placeholder="Search by UID"
            onDebounceChange={(event: string) => handleChange(event, 'uid')}
            enterButtonSize="8"
            searchWidth={'240px'}
          />
        )}
        {xxl && (
          <SearchInput
            value={uid}
            placeholder={isMobile ? 'Company UID' : ' Search by Company UID'}
            onDebounceChange={(event: string) => handleChange(event, 'companyUID')}
            enterButtonSize="8"
            searchWidth={'240px'}
          />
        )}
        {xl && (
          <SearchOptions
            SearchResult={SearchResultForCompany}
            onSelect={(value: any, { valId }: { valId: any }) => {
              if (valId) {
                setCompanyId(valId);
              }
            }}
            placeholder={isMobile ? 'Search company' : ' Search by company'}
            value={''}
            enterButtonSize="8"
            searchWidth={'240px'}
          />
        )}
        <span style={{ display: 'flex', gap: '10px' }}>
          {!xxl && (
            <Button size={'large'} onClick={() => setOpen(true)}>
              <FilterOutlined />
            </Button>
          )}
          {!xs && (
            <Button size={'large'} onClick={refetch}>
              Refresh
            </Button>
          )}
        </span>
      </span>
      <>
        {!xxl && (
          <Drawer
            title={null}
            closable={false}
            placement="bottom"
            onClose={() => setOpen(false)}
            open={open}
            height={xl ? 100 : md ? 130 : sm ? 200 : 340}
          >
            <span className="driver-filter-search">
              {!md && (
                <SearchInput
                  value={driverName}
                  placeholder="Search by name"
                  onDebounceChange={(event: string) => handleChange(event, 'name')}
                  enterButtonSize="8"
                  searchWidth={'100%'}
                />
              )}

              {!md && (
                <SearchInput
                  value={driverEmail}
                  placeholder="Search by email"
                  onDebounceChange={(event: string) => handleChange(event, 'email')}
                  enterButtonSize="8"
                  searchWidth={'100%'}
                />
              )}
              {!xxl && (
                <SearchInput
                  value={licenseNumber}
                  placeholder="Search by license"
                  onDebounceChange={(event: string) => handleChange(event, 'license')}
                  enterButtonSize="8"
                  searchWidth={'100%'}
                />
              )}
              <SearchInput
                value={uid}
                placeholder="Search by UID"
                onDebounceChange={(event: string) => handleChange(event, 'uid')}
                enterButtonSize="8"
                searchWidth={'100%'}
              />
              <SearchInput
                value={uid}
                placeholder="Search by CMP UID"
                onDebounceChange={(event: string) => handleChange(event, 'companyUID')}
                enterButtonSize="8"
                searchWidth={'100%'}
              />
              {!xl && (
                <SearchOptions
                  SearchResult={SearchResultForCompany}
                  onSelect={(value: any, { valId }: { valId: any }) => {
                    if (valId) {
                      setCompanyId(valId);
                    }
                  }}
                  placeholder="Search by company"
                  value={''}
                  enterButtonSize="8"
                  searchWidth={'100%'}
                />
              )}
            </span>
          </Drawer>
        )}
      </>
      <UsersTable
        data={data?.data}
        total={data?.count}
        isLoading={isLoading}
        isFetching={isFetching}
        setSortedInfo={setSortedInfo}
        sortedInfo={sortedInfo}
        setSkip={setSkip}
        refetch={refetch}
        onChange={onChange}
        pagination={false}
      />
    </div>
  );
};

export default Users;
